$(document).ready(function(){
    $(".toggle").on('click', function() {
        var id = $(this).data('id');

        $("#" + id + "-arrow").toggleClass("arrow_top arrow_bottom");

        if($("#" + id).is(":hidden")) {
            $(this).html('<img src="/img/minus.png" width="50">');

            $("#" + id).slideDown( "slow" );
        } else {
            $(this).html('<img src="/img/plus.png" width="50">');

            $("#" + id).slideUp();
        }
    });

    $("#content-slider").lightSlider({
        loop:true,
        keyPress:true,
        item:1,
        controls: false,
        onSliderLoad: function() {
            $('#image-gallery').removeClass('cS-hidden');
        }
    });


    $("#unique-slider").lightSlider({
        loop:false,
        keyPress:true,
        item:1,
        pager: false,
        controls: false,
        onSliderLoad: function() {
            $('#image-gallery').removeClass('cS-hidden');
        }
    });


    $('#menu').slicknav({
        prependTo: '#slicknav',
        label: ''
    });
});